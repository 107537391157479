<template>
    <div>
        <v-row justify="center" no-gutters>
          <v-col md="5">
            <div class="my-2 mx-4">
              <h3> {{ reportCard.student.full_name }}</h3>
              <h5>{{ reportCard.group.title }} - {{ reportCard.group.room.sections }}</h5>
              <v-list-item-subtitle>{{ moment(reportCard.group.date).format("DD MMM YYYY") }}</v-list-item-subtitle>
            </div>
              <v-card class="mx-4 my-2" elevation="2">
                <v-list-item>
                  <v-list-item-content>
                    <v-row justify="center" align="center" no-gutters>
                      <v-col cols="4" md="6">
                        <v-list-item-title class="font-weight-bold blue--text text-wrap">Assignment Average</v-list-item-title>
                      </v-col>
                      <v-col cols="8" md="6" class="text-right">
                        <v-rating
                          background-color="blue lighten-3"
                          color="blue"
                          :value="reportCard.assignment_star_count"
                          medium
                          length="5"
                          readonly
                        ></v-rating>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              
            <intract-smart-list
                disable-search
                :items="reportCard.group.parameter_groups"
                ref="parametergroupList"
            >
            <template v-slot:list-item="{ item: parameterGroup }">
              <v-card class="mx-4 my-2" elevation="2">
                <v-list-item>
                <v-list-item-content>             
                  <v-list-item-title class="mb-2 font-weight-bold primary--text">{{ parameterGroup.title }}</v-list-item-title> 
                  <v-list-item v-for="parameter in parameterGroup.group_parameters" :key="parameter.id" class="px-0">
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="text-wrap" >{{ parameter.title }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="my-0">
                       <v-rating
                          background-color="primary lighten-3"
                          color="primary"
                          v-if="reportCard.report_card_grades.find(grade => grade.parameter==parameter.id)"
                          :value="reportCard.report_card_grades.find(grade => grade.parameter==parameter.id).star_count"
                          medium
                          readonly
                        ></v-rating>
                        <span v-else>-</span>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-content>   
                </v-list-item>
              </v-card>            
            </template>
          </intract-smart-list>
        </v-col>
        </v-row>
    </div>
</template>

<script>
import moment from "moment";
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
export default {
  name: "ViewReportCard",
  mixins: [Mixins.essentials],

//   props: {
//     room: {
//       type: Object,
//       default: () => null,
//     },
//   },
  components: {
    // InfiniteLoading,
    IntractSmartList,
  },
  data() {
    return {
      moment: moment,
      reportCard: {
        group: {
          parameter_groups:[]
        },
        report_card_grades: [],
      },
    };
  },
  computed: {
  },
  methods: {
    async getReportCardDetails(){
      var url = this.endpoints.reportCardViewSet + `${this.$route.params.report_id}/`;
      url = this.Helper.addUrlParams(url,[`group_details=true`]);
      this.reportCard = await this.api.call(this.essentials, url)
    }
  },
  created() {
    this.getReportCardDetails();
  },
};
</script>
